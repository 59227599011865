import { ApplicationForm, Form } from '../form';
import { JsonData, UploadAccordion } from '../../../components/ui/upload';
import {
    KycData,
    fetchApplicationKYC,
    uploadApplicationKycDocs,
} from '@etica-js/api/src/appState/actions';
import React, { useEffect, useState } from 'react';
import { getStages, saveApplicationToContext, useFormNavigate } from '..';

import { ApplicationKYCData } from '@etica-js/api/src/schema';
import { toast } from 'react-toastify';
import { useAppContext } from '@etica-js/api/src/appState/state';
import { useParams } from 'react-router-dom';
type StagesType = ReadonlyArray<{ name: string; link: string; key: string }>;
export const VerifyKYC = () => {
    const { code, key } = useParams();
    const appCtx = useAppContext();
    const navigate = useFormNavigate();
    const [loading, setLoading] = useState(false);
    // eslint-disable-next-line no-undef
    const [stages, setStages] = useState<StagesType>([]);

    const [requirements, setRequirements] = useState<ApplicationKYCData>([]);

    const fetchData = async () => {
        const data = await saveApplicationToContext(
            code ?? '',
            key ?? '',
            appCtx
        );

        if (!data.success) {
            toast.error(`Failed - ${data?.message}`);
            return;
        }

        const classification = data.client?.classification;
        if (classification) {
            setStages(getStages(classification));
        }

        if (!data.success) {
            toast.error(`Failed - ${data?.message}`);
        }

        await fetchAppl();
    };

    const fetchAppl = async () => {
        const reqs = await fetchApplicationKYC(code ?? '', key ?? '');

        if (reqs.success) {
            setRequirements(reqs.data ?? []);
        }
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code, key]);

    const form: Form = {
        groups: [
            {
                name: 'kyc',
                fields: [],
            },
        ],
    };
    const isComplete = async () => {
        setLoading(true);

        await fetchAppl().finally(() => setLoading(false));

        let complete = true;
        requirements.forEach((req) => {
            req.list.forEach((doc) => {
                if (!doc.uploaded) {
                    complete = false;

                    toast.error(
                        `Please upload ${doc.name} for ${req.owner.name}`
                    );
                }
            });
        });

        return complete;
    };
    const onSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        isComplete().then((complete) => {
            if (complete) {
                navigate(`/apply/${code}/${key}/invest`);
            }
        });
    };

    const onUploadFile = (files: File[], data?: JsonData) => {
        uploadApplicationKycDocs(
            code ?? '',
            key ?? '',
            files,
            data as KycData
        ).then((data) => {
            if (data.success) {
                toast.success('Document uploaded successfully');
                fetchAppl();
            } else {
                toast.error(`Failed - ${data.error}`);
            }
        });
    };

    return (
        <ApplicationForm
            stages={stages}
            currentStage="kyc"
            form={form}
            onSubmit={onSubmit}
            loading={loading}
        >
            <div className="flex flex-wrap w-full">
                {requirements?.map((one) => (
                    <div className="w-full" key={one.owner.id}>
                        <h4 className="font-size-lg font-semibold mb-2">
                            {' '}
                            Documents for {one.owner.name}
                        </h4>
                        {one.list.map((doc) => (
                            <div className="w-full mb-5" key={doc.slug}>
                                <UploadAccordion
                                    title={`${doc.name} ${
                                        doc.required
                                            ? ' (required)'
                                            : ' (optional)'
                                    }`}
                                    onUploadFile={onUploadFile}
                                    description={doc.description ?? doc.name}
                                    extraData={{
                                        slug: doc.slug,
                                        file_name: doc.name,
                                        joint_id:
                                            one.owner.designation ===
                                            'principal'
                                                ? null
                                                : one.owner.id,
                                    }}
                                    uploaded={doc.uploaded}
                                />
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </ApplicationForm>
    );
};
