export const saveToken = async (type: string, value: string) => {
    // noop
};

export const getToken = async (type: string) => {
    // noop
};

export const deleteToken = async (type: string) => {
    // noop
};

export const deleteLoginTokens = async () => {
    // noop
};
