import { ApplicationForm, Form } from '../form';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';

import { get, post } from '@etica-js/api/src/framework/http';
import { getStages, saveApplicationToContext, useFormNavigate } from '..';

import type { Stages } from './email';

import styles from '../styles.module.scss';
import { toast } from 'react-toastify';
import { useAppContext } from '@etica-js/api/src/appState/state';
import { useAppUtils } from '@etica-js/api/src/appState/hooks';
import { useParams } from 'react-router';

type User = {
    exists: boolean;
    user?: {
        id: number;
        username: string;
        email?: string;
    };
};

export const CreatePassword = () => {
    const { code, key } = useParams();
    const [stages, setStages] = useState<Stages>([]);
    const { encrypt, can_encrypt } = useAppUtils();

    const [password, setPassword] = React.useState('');
    const [rePassword, setRePassword] = React.useState('');
    const navigate = useFormNavigate();
    const [user, setUser] = React.useState<User>({ exists: false });
    const appCtx = useAppContext();

    const [passwordFails, setPasswordFails] = React.useState({
        length: false,
        number: false,
        lowerCase: false,
        upperCase: false,
    });

    const Check = () => (
        <CheckCircleIcon className="w-4 inline text-blue-500 mr-2" />
    );
    const Fail = () => <XCircleIcon className="w-4 inline text-red-500 mr-2" />;

    useEffect(() => {
        const fetchData = async () => {
            const data = await saveApplicationToContext(
                code ?? '',
                key ?? '',
                appCtx
            );

            if (!data.success) {
                toast.error(`Failed - ${data?.message}`);
                return;
            }

            const classification = data.client?.classification;
            if (classification) {
                setStages(getStages(classification));
            }
        };

        const checkForUser = async () => {
            const resp = await get(
                `clients/applications/${code}/${key}/create-user`
            );

            if (resp.ok) {
                setUser(await resp.json());
            }
        };

        fetchData();
        checkForUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code, key]);

    const form: Form = {
        groups: [
            {
                name: 'verify-phone',
                fields: [],
            },
        ],
    };

    const onPasswordChange = (password: string) => {
        setPassword(password);
        const validation = passwordFails;

        validation.length = password.length >= 8;
        validation.number = /[0-9]/.test(password);
        validation.lowerCase = /[a-z]/.test(password);
        validation.upperCase = /[A-Z]/.test(password);

        setPasswordFails(validation);
    };

    const isValidPassword = () => {
        return (
            passwordFails.length &&
            passwordFails.number &&
            passwordFails.upperCase &&
            passwordFails.lowerCase
        );
    };

    const goNext = () => {
        const clientClass = appCtx.application?.type;

        if (clientClass === 'individual') {
            navigate(`/apply/${code}/${key}/invest`);
            return;
        }

        navigate(`/apply/${code}/${key}/kyc`);
    };

    const onSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (!user.exists) {
            if (password !== rePassword) {
                toast.error('Passwords do not match');
                return;
            }

            if (!isValidPassword()) {
                toast.error('Password does not meet complexity requirements');
                return;
            }
        }

        const resp = await post(
            `clients/applications/${code}/${key}/create-user`,
            {
                data: {
                    password: encrypt(password),
                    encrypted: can_encrypt,
                },
            }
        );
        const data = await resp.json();

        if (!resp.ok) {
            toast.error(
                data?.Message ?? 'Could not create user - check password'
            );
            return;
        }

        if (user.exists) {
            toast.success(`User ${data.username} updated successfully!`);
        } else {
            toast.success(`User ${data.username} created successfully!`);
        }

        appCtx.logger?.logUserAction('register', true);

        goNext();
    };

    if (user.exists) {
        return (
            <ApplicationForm
                stages={stages}
                currentStage="password"
                form={form}
                onSubmit={onSubmit}
            >
                <div className={styles.fieldGroup}>
                    <div>
                        You already have an existing user with username{' '}
                        {user.user?.username} and email {user.user?.email}.
                        Click next to proceed.
                    </div>
                </div>
            </ApplicationForm>
        );
    }

    return (
        <ApplicationForm
            stages={stages}
            currentStage="password"
            form={form}
            onSubmit={onSubmit}
        >
            <>
                <div className={styles.fieldGroup}>
                    <div className={styles.fieldset}>
                        <label htmlFor="">Enter password</label>
                        <div className="flex flex-wrap grow">
                            <input
                                className="grow"
                                type="password"
                                name="password"
                                placeholder="Enter password"
                                onChange={(e) =>
                                    onPasswordChange(e.currentTarget.value)
                                }
                            />
                            <br />
                            <div className="w-full">
                                <div className="">
                                    {passwordFails.length ? (
                                        <Check />
                                    ) : (
                                        <Fail />
                                    )}{' '}
                                    MUST be at least 8 characters
                                </div>
                                <div className="">
                                    {passwordFails.upperCase ? (
                                        <Check />
                                    ) : (
                                        <Fail />
                                    )}{' '}
                                    MUST have at least one upper case character
                                    (A-Z)
                                </div>
                                <div className="">
                                    {passwordFails.lowerCase ? (
                                        <Check />
                                    ) : (
                                        <Fail />
                                    )}{' '}
                                    MUST have at lease one lower case character
                                    (a-z)
                                </div>
                                <div className="">
                                    {passwordFails.number ? (
                                        <Check />
                                    ) : (
                                        <Fail />
                                    )}{' '}
                                    MUST have at least one number (0-9)
                                </div>
                                {/* <div className="">MAY have a special character (:;?''"")</div> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.fieldGroup}>
                    <div className={styles.fieldset}>
                        <label htmlFor="">Repeat password</label>
                        <input
                            type="password"
                            name="repeat_password"
                            placeholder="Repeat password"
                            onChange={(e) =>
                                setRePassword(e.currentTarget.value)
                            }
                        />
                    </div>
                </div>
            </>
        </ApplicationForm>
    );
};
