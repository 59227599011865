import { fetchFeatures, fetchSettings } from './actions';

import { decode } from 'base-64';
import { encrypt } from '@etica-js/utils/src/encryption';
import { getItem } from '../framework/storage';
import { keys } from '@etica-js/utils/src/functional';
import { useAppContext } from './state';
import { useAuthContext } from '../auth/state';
import { useEffect } from 'react';

const feature_list = {
    utility_bills: false,
    send_money: false,
    tokenless_login: false,
    mpesa_bills: false,
};

type FeatureObj = typeof feature_list;
type FeatureName = keyof FeatureObj;

export const useSettings = (skipCredentials?: boolean) => {
    const appCtx = useAppContext();

    useEffect(() => {
        if (!appCtx.settings) {
            fetchSettings(appCtx.dispatch, skipCredentials);
        }
    }, [appCtx.settings, appCtx.dispatch, skipCredentials]);
};

export const useFeatures = (skipCredentials?: boolean) => {
    const appCtx = useAppContext();

    useEffect(() => {
        const withAuth =
            appCtx.features.source === 'network' && !skipCredentials;

        if (
            appCtx.features.source !== 'network' &&
            withAuth !== appCtx.features.withAuth
        ) {
            fetchFeatures(appCtx.dispatch, skipCredentials);
        }
    }, [
        appCtx.dispatch,
        appCtx.features.source,
        appCtx.features.withAuth,
        skipCredentials,
    ]);

    const initial = { ...feature_list };

    // add values from cache if feature state is empty
    appCtx.features.source === undefined &&
        getItem<typeof feature_list>('features', true).then((cache) => {
            if (cache && appCtx.features.source !== 'network') {
                appCtx.dispatch({
                    type: 'SET_FEATURES',
                    payload: {
                        source: 'cache',
                        list: keys(cache).map((key) => ({
                            name: key,
                            enabled: cache[key],
                        })),
                    },
                });
            }
        });

    // override defaults with values from network
    appCtx.features.list?.forEach((feature) => {
        initial[feature.name as FeatureName] = feature.enabled ?? false;
    });

    return initial;
};

export const useAppUtils = () => {
    const appCtx = useAppContext();

    return {
        can_encrypt: !!appCtx.settings?.public_key,
        encrypt: (plaintext: string) => {
            if (!appCtx.settings?.public_key) {
                return plaintext;
            }

            return encrypt(plaintext, decode(appCtx.settings.public_key));
        },
    };
};

export const useContextInitialization = () => {
    const { user } = useAuthContext();
    useSettings();
    useFeatures(!user?.username);
};
