import 'react-datepicker/dist/react-datepicker.css';
import styles from './ui.module.scss';

const RootPicker = require('react-datepicker').default;

type Props = {
    selected?: Date;
    startDate?: Date;
    endDate?: Date;
    selectsRange?: Boolean;
    onChange: (date: Date) => void;
    placeholderText?: string;
    className?: string;
    wrapperClassName?: string;
    calendarClassName?: string;
    isClearable?: boolean;
};

export const DatePicker = ({
    selected,
    startDate,
    endDate,
    onChange,
    selectsRange,
    placeholderText,
    className,
    wrapperClassName,
    calendarClassName,
    isClearable,
}: Props) => {
    return (
        <RootPicker
            className={'rootPicker ' + className}
            calendarClassName={
                styles.datePickerCalendar + ' ' + calendarClassName
            }
            wrapperClassName={styles.datePickerInput + ' ' + wrapperClassName}
            selected={selected}
            onChange={onChange}
            selectsRange={selectsRange}
            startDate={startDate}
            endDate={endDate}
            placeholderText={placeholderText}
            isClearable={isClearable}
        />
    );
};
