import { JsonData, UploadAccordion } from '../../components/ui/upload';
import { KycData, uploadKycDocs } from '@etica-js/api/src/appState/actions';

import { refreshDocs } from '.';
import styles from './dashboard.module.scss';
import { toast } from 'react-toastify';
import { useAppContext } from '@etica-js/api/src/appState/state';
import { useMemo } from 'react';
import { useParams } from 'react-router';

export const KycPage = () => {
    const appCtx = useAppContext();
    const { code } = useParams();

    const kycStatus = useMemo(() => {
        const filtered = appCtx.kycStatus?.kyc?.filter(
            (client) => client.code.toString() === code
        );

        return filtered ? filtered[0] : undefined;
    }, [code, appCtx]);

    const onUploadFile = (files: File[], data?: JsonData) => {
        uploadKycDocs(files, data as KycData).then(
            (resp: { success: boolean; error?: string }) => {
                if (!resp.success) {
                    toast.error(resp.error);
                } else {
                    toast.success('File uploaded successfully');
                }
                refreshDocs(appCtx.dispatch);
            }
        );
    };

    return (
        <div>
            <h3 className="text-xl font-bold mb-5">
                Upload your KYC documents
            </h3>

            <div className="flex flex-wrap bg-white p-10 rounded-xl">
                <div
                    className={styles.kycTitle + ' flex flex-wrap w-full mb-5'}
                >
                    <div className={`${styles.selected} ${styles.title}`}>
                        KYC Documents - {kycStatus?.name} : {kycStatus?.code}
                    </div>
                </div>
                <div className="flex flex-wrap w-full md:w-3/4">
                    {!kycStatus && <div>No missing documents found</div>}
                    {kycStatus?.count === 0 && (
                        <div className="mb-5">
                            You have uploaded all documents
                        </div>
                    )}

                    {kycStatus?.required_docs?.map((ownership) => (
                        <div
                            key={ownership?.owner.id}
                            className="flex flex-wrap w-full"
                        >
                            <div className="text-gray-700 mb-2 bg-gray-200 rounded w-full p-4">
                                Upload documents for {ownership?.owner.name}
                            </div>

                            {ownership.list.map((doc) => (
                                <UploadAccordion
                                    key={doc.slug}
                                    title={`${doc.name} ${
                                        doc.required
                                            ? ' (required)'
                                            : ' (optional)'
                                    }`}
                                    footer="jpg/png files with a size less than 500kb"
                                    description={doc.description ?? doc.name}
                                    extraData={{
                                        slug: doc.slug,
                                        client_code: kycStatus.code,
                                        file_name: doc.name,
                                        joint_id:
                                            ownership.owner.designation ===
                                            'principal'
                                                ? null
                                                : ownership.owner.id,
                                    }}
                                    onUploadFile={onUploadFile}
                                    uploaded={doc.uploaded}
                                />
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
