import styles from './widgets.module.scss';

export const BillsWidget = () => {
    return (
        <div className={styles.billsContainer}>
            {/* <h3 className="text-xl font-bold mb-5">Pay Utility Bills</h3>

      <div className="flex flex-wrap">
        <div className={styles.bill}>
          <img src="https://via.placeholder.com/93.png?text=KPLC" />
        </div>
        <div className={styles.bill}>
          <img src="https://via.placeholder.com/93.png?text=DSTV" />
        </div>
        <div className={styles.bill}>
          <img src="https://via.placeholder.com/93.png?text=GoTV" />
        </div>
      </div> */}
        </div>
    );
};
