import React, { useState } from 'react';
import { useAppUtils, useFeatures } from '@etica-js/api/src/appState/hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { LoginPane } from '../../components/auth/login';
import { post } from '@etica-js/api/src/framework/http';
import { toast } from 'react-toastify';

export const Login = () => {
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [token, setToken] = React.useState('');
    const [isEnteringPassword, setIsEnteringPassword] = React.useState(true);
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [tokenMethod, setTokenMethod] = useState('');
    const { tokenless_login } = useFeatures(true);
    const { encrypt, can_encrypt } = useAppUtils();

    const requestToken = async () => {
        setLoading(true);
        const resp = await post('token/request-2fa', {
            data: {
                username,
                password: encrypt(password),
                token_method: tokenMethod,
                encrypted: can_encrypt,
            },
        }).finally(() => setLoading(false));

        const data = await resp.json();

        if (resp.ok) {
            toast.success('Please enter token from SMS/Email');

            setIsEnteringPassword(false);
            return;
        }
        toast.error(data?.Message ?? 'Could not send token');
    };

    const authenticate = async () => {
        setLoading(true);

        const resp = await post(`token`, {
            data: {
                username,
                password: encrypt(password),
                token,
                encrypted: can_encrypt,
            },
            params: {
                type: 'session',
            },
        }).finally(() => setLoading(false));

        const data = await resp.json();
        if (resp.ok) {
            toast.success('Logged in successfully');

            const redir = params.get('redir') ?? '/dashboard';

            navigate(redir);
            return;
        }

        toast.error(data?.Message ?? 'Could not complete login, try again');
    };

    const onSubmit = () => {
        if (isEnteringPassword && !tokenless_login) {
            requestToken();
            return;
        }

        if (tokenless_login && !token) {
            setToken('12345');
        }

        authenticate();
    };

    return (
        <LoginPane
            title="Welcome to Etica Capital"
            description="The investment platform of your choice where you imagine more"
            fields={[
                {
                    id: 'username',
                    type: 'text',
                    label: 'Username or email',
                    placeholder: 'Enter username or email address',
                    onChange: (event) => {
                        setUsername(event.currentTarget.value);
                    },
                    hide: !isEnteringPassword,
                },
                {
                    id: 'password',
                    type: 'password',
                    label: 'Password',
                    placeholder: 'Enter password',
                    onChange: (event) => {
                        setPassword(event.currentTarget.value);
                    },
                    hide: !isEnteringPassword,
                },
                {
                    id: 'token_method',
                    type: 'select',
                    label: 'Send Login Token Via',
                    onChange: (event) => {
                        setTokenMethod(event.currentTarget.value);
                    },
                    hide: !isEnteringPassword || tokenless_login,
                    choices: [
                        { value: 'sms', label: 'SMS' },
                        { value: 'email', label: 'Email' },
                    ],
                },
                {
                    id: 'token',
                    type: 'number',
                    label: 'Login Token',
                    placeholder: 'Enter 6-digit token from SMS/Email',
                    onChange: (event) => {
                        setToken(event.currentTarget.value);
                    },
                    hide: isEnteringPassword,
                },
            ]}
            links={[
                { text: 'Create Account', location: '/apply' },
                { text: 'Forgot password', location: '/auth/forgot-password' },
            ]}
            submit={{ value: 'NEXT', fn: onSubmit, loading }}
        />
    );
};
