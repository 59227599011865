import { ArrowPathIcon } from '@heroicons/react/24/outline';
import React from 'react';

import form from '../../assets/styles.module.scss';
import { post } from '@etica-js/api/src/framework/http';
import { toast } from 'react-toastify';

type ConfirmationProps = {
    type: 'confirmation';
    title?: string;
    message?: string;
    data: ReadonlyArray<{ label: string; value?: string | number }>;
    children?: JSX.Element | JSX.Element[];
    setToken?: (token: string) => void;
    tokenMethod?: string;
    tokenLabel?: string;
};

export type Stage = undefined | ConfirmationProps;

export const getLabel = (
    choice: string | number,
    choices: ReadonlyArray<{ label: string; value: string | number }>
) => {
    return choices.find((c) => c.value === choice)?.label ?? '';
};

export const requestToken = (token_method: string, reason?: string) => {
    return post('client-api/request-token', {
        data: { token_method, reason: reason ?? '' },
    });
};

export const Confirmation: React.FC<ConfirmationProps> = (props) => {
    const resendToken = () => {
        requestToken(props.tokenMethod ?? 'sms').then((resp) => {
            if (resp.ok) {
                toast.success('Token sent successfully');
                return;
            }

            toast.error('Failed to send token');
        });
    };
    return (
        <div className="flex flex-wrap w-full">
            <h3 className="text-xl font-bold mb-5">{props.title}</h3>

            <div className="w-full">
                <p className="mb-5">{props.message}</p>
                {props.data.map((item) => (
                    <div className="flex flex-wrap mb-2" key={item.label}>
                        <div className="w-1/2 font-semibold">{item.label}</div>
                        <div className="w-1/2">{item.value}</div>
                    </div>
                ))}

                {props.setToken && (
                    <div className={form.fieldset}>
                        <label htmlFor="">
                            {props.tokenLabel ?? 'Enter token from sms/email'}
                            {props.tokenMethod && (
                                <button
                                    type="button"
                                    className="tooltip"
                                    onClick={() => resendToken()}
                                >
                                    <ArrowPathIcon className="w-5 text-blue-400" />
                                    <span className="tooltip-text">
                                        Resend token
                                    </span>
                                </button>
                            )}
                        </label>
                        <input
                            type="number"
                            onChange={(e) =>
                                props.setToken?.(e.currentTarget.value)
                            }
                            required
                        />
                    </div>
                )}
            </div>

            {props.children}
        </div>
    );
};
