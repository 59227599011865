import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

import { Currency } from './formatting';
import Rect from '@etica-js/assets/src/images/product.png';
import styles from './widgets.module.scss';
import { useAppContext } from '@etica-js/api/src/appState/state';
import { useRef } from 'react';

export const ProductsWidget = () => {
    const appCtx = useAppContext();
    const scrollable = useRef<HTMLDivElement>(null);

    const setOpenModal = (name: string, product: string) => {
        appCtx.dispatch({
            type: 'SET_OPEN_MODAL',
            payload: { name, target: product },
        });
    };

    const Logo = () => {
        return (
            <div className="w-60">
                <img src={Rect} alt="" />
            </div>
        );
    };

    const scroll = (scrollOffset: number) => {
        if (scrollable.current) {
            scrollable.current.scrollLeft += scrollOffset;
        }
    };

    const formatLockIn = (lock?: string) => {
        if (!lock) {
            return 'None';
        }

        if (lock.endsWith('y')) {
            return `${lock.slice(0, -1)} years`;
        }

        if (lock.endsWith('m')) {
            return `${lock.slice(0, -1)} months`;
        }

        if (lock.endsWith('d')) {
            return `${lock.slice(0, -1)} days`;
        }

        return lock;
    };

    return (
        <div>
            <h3 className="text-xl font-bold mb-5">Our Products</h3>

            <div className={styles.myInvestment + ' flex'} ref={scrollable}>
                {appCtx.products?.map((p) => (
                    <div
                        key={p.short_name}
                        className={
                            styles.myInvestmentCard +
                            ' bg-white shrink text-sm flex flex-wrap md:w-1/3 lg:w-1/4'
                        }
                    >
                        <div className="w-full">
                            <Logo />
                        </div>

                        <h4 className="font-bold"> {p.name}</h4>
                        <div className={styles.moreInfo}>
                            <table className="w-full">
                                <tbody>
                                    <tr>
                                        <td className="text-right">Minimum:</td>
                                        <td className="font-semibold text-right">
                                            <Currency
                                                value={
                                                    p.fund
                                                        .minimum_investment_amount
                                                }
                                                currency={p.currency.code}
                                            />
                                        </td>
                                    </tr>
                                    {p.performance.price &&
                                        p.performance.price !== 1 && (
                                            <tr>
                                                <td className="text-right">
                                                    Unit Price:
                                                </td>
                                                <td className="font-semibold text-right">
                                                    <Currency
                                                        value={
                                                            p.performance.price
                                                        }
                                                        currency={
                                                            p.currency.code
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                        )}

                                    <tr>
                                        <td className="text-right">
                                            Lock in period:
                                        </td>
                                        <td className="font-semibold text-right">
                                            {formatLockIn(p.lock_in_period)}
                                        </td>
                                    </tr>

                                    {!!p.performance.spot?.gross_annual && (
                                        <tr>
                                            <td className="text-right">
                                                Effective Annual{' '}
                                                {p.fund.interest_term ??
                                                    'Yield'}
                                                :
                                            </td>
                                            <td className="font-semibold text-right">
                                                <Currency
                                                    value={
                                                        p.performance.spot
                                                            ?.gross_annual ?? 0
                                                    }
                                                    currency=""
                                                />
                                                %
                                            </td>
                                        </tr>
                                    )}
                                    {!!p.performance.spot?.gross_daily && (
                                        <tr>
                                            <td className="text-right">
                                                Daily{' '}
                                                {p.fund.interest_term ??
                                                    'Yield'}
                                                :
                                            </td>
                                            <td className="font-semibold text-right">
                                                <Currency
                                                    value={
                                                        p.performance.spot
                                                            ?.gross_daily ?? 0
                                                    }
                                                    currency=""
                                                />
                                                %
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div className="flex flex-wrap justify-end content-end mt-5 w-full">
                            <button
                                className="small primary button"
                                onClick={() =>
                                    setOpenModal('invest', p.short_name)
                                }
                            >
                                Open new account
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            <div className="justify-items-end">
                <button className="mr-5" onClick={() => scroll(-100)}>
                    <ChevronLeftIcon className="w-5" />
                </button>
                <button onClick={() => scroll(100)}>
                    <ChevronRightIcon className="w-5" />
                </button>
            </div>
        </div>
    );
};
