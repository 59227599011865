import { Action, useAppContext } from '@etica-js/api/src/appState/state';

import {
    fetchFeatures,
    fetchInvestments,
    fetchKycStatus,
    fetchProducts,
} from '@etica-js/api/src/appState/actions';
import { useLocation, useNavigate } from 'react-router-dom';

import { fetchUser } from '@etica-js/api/src/auth/actions';
import { toast } from 'react-toastify';
import { useAuthContext } from '@etica-js/api/src/auth/state';
import { useEffect } from 'react';

export const refreshDocs = async (dispatch?: React.Dispatch<Action>) => {
    const missing_docs = fetchKycStatus();
    dispatch?.({ type: 'SET_KYC_STATUS', payload: await missing_docs });
};

export const useAppState = () => {
    const auth = useAuthContext();
    const appContext = useAppContext();
    const navigate = useNavigate();
    const nav = useLocation();

    useEffect(() => {
        const initialize = async () => {
            const user = await fetchUser();

            if (!user) {
                navigate(`/auth/login?redir=${nav.pathname}`);
                toast.warn('You are not logged in');

                return;
            }

            auth.dispatch?.({ type: 'FETCH', payload: user });

            fetchInvestments(appContext.dispatch);
            refreshDocs(appContext.dispatch);

            fetchProducts(appContext.dispatch);
            fetchFeatures(appContext.dispatch);
        };

        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
