import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { NotFoundPage } from '../pages/NotFound';

export function ErrorBoundary() {
    const error = useRouteError();

    if (isRouteErrorResponse(error) && error.status === 404) {
        // the response json is automatically parsed to
        // `error.data`, you also have access to the status
        return <NotFoundPage />;
    }

    // rethrow to let the parent error boundary handle it
    // when it's not a special case for this route
    throw error;
}
