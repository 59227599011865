import { Aside, AsideProps } from '../ui/aside';
import { Confirmation, Stage } from '.';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    createWithdrawal,
    createWithdrawalPresentment,
    fetchClientBankAccounts,
} from '@etica-js/api/src/appState/actions';

import { ClientBankAccount } from '@etica-js/api/src/schema';
import { LoadingAnimation } from '../ui/loading';
import { Modal } from '../ui/modal';

import { currencyFormat } from '../widgets/formatting';
import form from '../../assets/styles.module.scss';
import { toast } from 'react-toastify';
import { useAppContext } from '@etica-js/api/src/appState/state';
import { useNavigate } from 'react-router-dom';

type Props = {
    onClose: AsideProps['onClose'];
};

export const Withdraw: React.FC<Props> = (props) => {
    const appCtx = useAppContext();
    const [account, setAccount] = useState('');
    const [amount, setAmount] = useState(0);
    const [bankAccounts, setBankAccounts] = useState<ClientBankAccount[]>([]);
    const [paymentAccount, setPaymentAccount] = useState('');
    const [loading, setLoading] = useState(false);
    const [complete, setComplete] = useState(false);
    const ref = useRef<HTMLSelectElement>(null);
    const [stage, setStage] = useState<Stage>(undefined);
    const [tokenMethod, setTokenMethod] = useState('sms');
    const [token, setToken] = useState('');
    const [hasNoBankAccount, setHasNoBankAccount] = useState(false);
    const navigate = useNavigate();

    const bankAccLabel = (acc?: ClientBankAccount) =>
        acc
            ? `Name: ${acc.name} ${acc.number} (${acc.currency}) Bank: ${acc.bank}`
            : 'None';

    const getAccount = useCallback(() => {
        return appCtx
            .process('GET_ACCOUNTS')
            .filter((acc) => acc.product.fund.calculator !== 'fixed_interest');
    }, [appCtx]);

    const getClientCode = useCallback(
        (acc_no: string) =>
            appCtx.process('GET_ACCOUNTS').find((acc) => acc.number === acc_no)
                ?.code,
        [appCtx]
    );

    const getPaymentAccount = useCallback(
        (number: string) => {
            const code = getClientCode(number);

            if (!code) {
                return;
            }
            fetchClientBankAccounts(code).then((accs) => {
                if (!accs.length) {
                    setHasNoBankAccount(true);
                }

                setBankAccounts(accs);
            });
        },
        [getClientCode]
    );

    const getSelectedAccount = useMemo(
        () => getAccount().find((acc) => acc.number === account),
        [account, getAccount]
    );
    const getSelectedPaymentAccount = useMemo(
        () => bankAccounts.find((acc) => acc.id === paymentAccount),
        [paymentAccount, bankAccounts]
    );

    const diffCurrency = useMemo(
        () =>
            getSelectedAccount?.product.currency.code !==
            getSelectedPaymentAccount?.currency,
        [getSelectedAccount, getSelectedPaymentAccount]
    );

    const submitWithdrawal = (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);

        if (!stage) {
            createWithdrawalPresentment(
                amount,
                account,
                paymentAccount,
                tokenMethod
            )
                .then((result) => {
                    if (result.is_error) {
                        toast.error('Failed - ' + result.message);
                        return;
                    }

                    const exchangeRate = result.data?.exchange_rate;

                    const fees =
                        result.data?.fees?.map((fee) => ({
                            label: fee.name,
                            value: currencyFormat({ value: fee.amount }),
                        })) ?? [];

                    setStage({
                        type: 'confirmation',
                        message: 'Confirm your withdrawal details below',
                        setToken,
                        tokenMethod,
                        data: [
                            {
                                label: 'Account',
                                value: getAccount().find(
                                    (acc) => acc.number === account
                                )?.label,
                            },
                            {
                                label: 'Amount',
                                value: currencyFormat({
                                    value: amount,
                                    currency:
                                        getSelectedAccount?.product.currency
                                            .code,
                                }),
                            },
                            {
                                label: 'Bank Account',
                                value: bankAccLabel(
                                    bankAccounts.find(
                                        (acc) => acc.id === paymentAccount
                                    )
                                ),
                            },
                            ...fees,
                            ...(diffCurrency
                                ? [
                                      {
                                          label: 'Exchange rate',
                                          value: exchangeRate
                                              ? exchangeRate > 1
                                                  ? exchangeRate
                                                  : 1 / exchangeRate
                                              : 'Exchange rate not available',
                                      },
                                      {
                                          label: 'Converted Amount',
                                          value: exchangeRate
                                              ? currencyFormat({
                                                    value:
                                                        amount * exchangeRate,
                                                    currency:
                                                        getSelectedPaymentAccount?.currency,
                                                })
                                              : 'Exchange rate not available',
                                      },
                                  ]
                                : []),
                        ],
                    });
                })
                .finally(() => setLoading(false));

            return;
        }

        createWithdrawal(amount, account, paymentAccount, token)
            .then((result) => {
                appCtx.logger?.logUserAction('withdraw', !result.is_error);
                if (result.is_error) {
                    toast.error('Failed - ' + result.message);
                    return;
                }
                toast.success('Saved successfully');
                setComplete(true);
            })
            .finally(() => setLoading(false));
    };

    const accountSelected = (number: string) => {
        setAccount(number);
        getPaymentAccount(number);
    };

    useEffect(() => {
        accountSelected(appCtx.ui.openModal.target);
        if (ref.current) {
            ref.current.value = appCtx.ui.openModal.target;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appCtx.ui.openModal.target]);

    return (
        <Aside onClose={props.onClose} success={complete}>
            <div className="flex flex-wrap p-3">
                <h3 className="text-xl font-bold mb-5 mt-10">Withdrawal</h3>

                <Modal
                    showModal={hasNoBankAccount}
                    setShowModal={setHasNoBankAccount}
                    text="Selected account has no payment account
                    linked, click button below to create
                    one."
                    primaryButtonText="Create Account"
                    primaryButtonAction={() => {
                        props.onClose();
                        navigate(
                            `profile/${getClientCode(account)}/add-bank-account`
                        );
                    }}
                    secondaryButtonAction={() => setHasNoBankAccount(false)}
                    secondaryButtonText="Close"
                />

                <form onSubmit={submitWithdrawal} className="w-full">
                    {stage && stage.type === 'confirmation' && (
                        <Confirmation {...stage} />
                    )}

                    <div className="w-full">
                        {!stage && (
                            <>
                                <div className={form.fieldset}>
                                    <label htmlFor="">Account</label>
                                    <select
                                        onChange={(e) =>
                                            accountSelected(
                                                e.currentTarget.value
                                            )
                                        }
                                        required
                                        defaultValue={
                                            appCtx.ui.openModal.target
                                        }
                                        ref={ref}
                                        disabled={!!appCtx.ui.openModal.target}
                                    >
                                        <option value="">SELECT ACCOUNT</option>
                                        {getAccount()?.map((acc) => (
                                            <option
                                                key={acc.number}
                                                value={acc.number}
                                            >
                                                {acc.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className={form.fieldset}>
                                    <label htmlFor="">Amount</label>
                                    <input
                                        type="number"
                                        onChange={(e) =>
                                            setAmount(
                                                parseFloat(
                                                    e.currentTarget.value
                                                )
                                            )
                                        }
                                        required
                                    />
                                </div>
                                <div className={form.fieldset}>
                                    <label htmlFor="">Payment Account</label>
                                    <select
                                        onChange={(e) =>
                                            setPaymentAccount(
                                                e.currentTarget.value
                                            )
                                        }
                                        required
                                    >
                                        <option value="">
                                            SELECT PAYMENT ACCOUNT
                                        </option>
                                        {bankAccounts.map((acc) => (
                                            <option key={acc.id} value={acc.id}>
                                                {bankAccLabel(acc)}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className={form.fieldset}>
                                    <label htmlFor="">
                                        Send Verification Token Via
                                    </label>

                                    <select
                                        className={form.fieldset}
                                        onChange={(e) =>
                                            setTokenMethod(
                                                e.currentTarget.value
                                            )
                                        }
                                    >
                                        <option value="sms">SMS</option>
                                        <option value="email">Email</option>
                                    </select>
                                </div>
                            </>
                        )}
                        <div className={form.fieldset}>
                            {stage && stage.type === 'confirmation' && (
                                <button
                                    className="secondary button w-full mb-2"
                                    onClick={() => setStage(undefined)}
                                >
                                    Back
                                </button>
                            )}

                            <button
                                className="primary button w-full"
                                type="submit"
                            >
                                <LoadingAnimation loading={loading} />
                                {stage ? 'Confirm' : 'Save'}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </Aside>
    );
};
