import { AccType, getStages, useFormNavigate } from '..';
import { ApplicationForm, Form } from '../form';

import { PersonalDetailsProps } from './individual';
import React from 'react';
import { post } from '@etica-js/api/src/framework/http';
import { toast } from 'react-toastify';

export const CorporateForm: React.FC<PersonalDetailsProps> = (props) => {
    const stages = getStages(props.accountType as AccType[number]['value']);
    const navigate = useFormNavigate();

    const [formState, setFormState] = React.useState<{
        [key: string]: string | number;
    }>({});

    const saveInput = (
        e: React.FormEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        let state = formState;
        state[e.currentTarget.name] = e.currentTarget.value;
        setFormState(state);
    };

    const onSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        // @ts-ignore:next-line
        const source = window.APPLICATION_CLIENT ?? 'web';

        const resp = await post('clients/self-register/corporate', {
            data: { ...formState, classification: props.accountType, source },
        });

        const data = await resp.json();

        if (resp.ok) {
            toast.success('Saved successfully');
            navigate(`${data.code}/${data.key}/verify-phone`);
            return;
        }

        toast.error(`Failed - ${data?.Message}`);
    };

    const form: Form = {
        groups: [
            {
                name: 'corporate',
                fields: [
                    {
                        label: 'Name',
                        type: 'text',
                        name: 'company_name',
                        placeholder: 'Company Name',
                        onChange: saveInput,
                    },
                    {
                        name: 'email',
                        label: 'Email Address',
                        placeholder: 'Email Address',
                        type: 'email',
                        onChange: saveInput,
                    },
                    {
                        name: 'phone',
                        label: 'Phone Number (include country code)',
                        placeholder: 'Phone Number',
                        type: 'tel',
                        onChange: saveInput,
                    },
                    {
                        name: 'company_reg_no',
                        label: 'Company registration number',
                        placeholder: 'Company reg. no.',
                        type: 'text',
                        onChange: saveInput,
                    },
                ],
            },
        ],
    };

    return (
        <ApplicationForm
            stages={stages}
            currentStage="personal"
            form={form}
            onSubmit={onSubmit}
        />
    );
};
