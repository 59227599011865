import {
    ChevronLeftIcon,
    ChevronRightIcon,
    CreditCardIcon,
    TrashIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';

import {
    deleteBillPayment,
    getSavedBills,
    saveBillPayment,
} from '@etica-js/api/src/appState/actions';

import { Confirmation } from '../../ui/confirmation';
import { LoadingAnimation } from '../../ui/loading';
import { SavedBill } from '@etica-js/api/src/schema';

import form from '../../../assets/styles.module.scss';
import { toast } from 'react-toastify';

type Props = {
    onSelect: (selected: SavedBill) => void;
};

export const SavedBillsManager: React.FC<Props> = (props) => {
    const [savedBills, setSavedBills] = useState<readonly SavedBill[]>([]);
    const [previousPage, setPreviousPage] = React.useState<string | undefined>(
        undefined
    );
    const [nextPage, setNextPage] = React.useState<string | undefined>(
        undefined
    );
    const [loading, setIsLoading] = useState(false);

    const fetchPage = (url?: string) => {
        setIsLoading(true);
        getSavedBills(url)
            .then((data) => {
                if (data.is_error) {
                    toast.error(data.message);
                    return;
                }
                setSavedBills(data.data?.results ?? []);
                setPreviousPage(data.data?.previous);
                setNextPage(data.data?.next);
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        fetchPage();
    }, []);

    return (
        <>
            <h5 className="text-xl font-bold mb-5">Select bill to pay</h5>
            {savedBills?.length > 0 ? (
                <div className="flex flex-wrap">
                    {savedBills.map((bill) => (
                        <DisplayBill
                            key={bill.id}
                            data={bill}
                            onDelete={fetchPage}
                            onSelect={(bill) => props.onSelect(bill)}
                        />
                    ))}
                </div>
            ) : (
                <p className="mb-5">
                    {loading ? 'Loading...' : 'No bills to show'}
                </p>
            )}
            <div className="justify-items-end">
                {!!previousPage && (
                    <button
                        className="mr-5"
                        onClick={() => fetchPage(previousPage)}
                    >
                        <ChevronLeftIcon className="w-5" />
                    </button>
                )}

                {!!nextPage && (
                    <button onClick={() => fetchPage(nextPage)}>
                        <ChevronRightIcon className="w-5" />
                    </button>
                )}
            </div>
        </>
    );
};

export const DisplayBill: React.FC<{
    data: SavedBill;
    onDelete?: () => void;
    onSelect?: (bill: SavedBill) => void;
    onClear?: () => void;
}> = (props) => {
    const onConfirmDelete = (id: number) => {
        deleteBillPayment(id).then((resp) => {
            if (resp.is_error) {
                toast.error(resp.message);
                return;
            }
            toast.success('Bill deleted successfully');
            props.onDelete?.();
        });
    };
    const onSelect = (event: React.FormEvent) => {
        event.preventDefault();

        props?.onSelect?.(props.data);
    };

    const onClear = (event: React.FormEvent) => {
        event.preventDefault();

        props?.onClear?.();
    };
    return (
        <div
            className={
                'flex flex-wrap rounded mb-3 mr-3 w-full p-3 bg-slate-50 border-slate-400 text-slate-700'
            }
        >
            <div className="w-full justify-between flex flex-wrap mb-2">
                <div>{props.data.name}</div>
                <div className="text-sm">
                    {props.onSelect && (
                        <button
                            className="small primary button mr-2"
                            onClick={onSelect}
                        >
                            <CreditCardIcon className="w-5 text-white inline" />{' '}
                            Pay
                        </button>
                    )}
                    {props.onDelete && (
                        <Confirmation
                            btnClass="small secondary button mr-2"
                            btnText={
                                <TrashIcon className="w-5 text-white inline" />
                            }
                            confirmationText="Are you sure you want to delete this bill?"
                            confirmBtnAction={() =>
                                onConfirmDelete(props.data.id)
                            }
                        />
                    )}
                    {props.onClear && (
                        <button
                            className="small secondary button mr-2"
                            onClick={onClear}
                        >
                            <XMarkIcon className="w-5 text-white inline" />
                        </button>
                    )}
                    <div></div>
                </div>
            </div>
            <div className="w-full flex flex-wrap justify-between text-sm">
                <div>
                    {props.data.provider_display ?? props.data.provider}{' '}
                    {props.data.provider !== 'utility' &&
                        props.data.bill_number}
                </div>
                <div>Account: {props.data.account}</div>
            </div>
        </div>
    );
};

type SaveProps = {
    savedBill?: SavedBill;
    bill_id?: number | string;
    provider: string;
    bill_number: string;
    account: string;
};

export const SaveNewBill: React.FC<SaveProps> = ({
    savedBill,
    bill_id,
    provider,
    bill_number,
    account,
}) => {
    const [complete, setComplete] = useState(false);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');

    const onSave = (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);
        saveBillPayment({
            name,
            bill_id,
            provider,
            bill_number,
            account,
        })
            .then((resp) => {
                if (resp.is_error) {
                    toast.error(resp.message);
                    return;
                }
                toast.success(
                    "Bill saved successfully. You can now find it under 'saved bills'"
                );
                setComplete(true);
            })
            .finally(() => setLoading(false));
    };

    if (savedBill || complete) {
        return <></>;
    }
    return (
        <div className="flex w-full">
            <form className="w-full" onSubmit={onSave}>
                <div className={form.fieldset}>
                    <label htmlFor="">Give bill a name</label>
                    <input
                        type="text"
                        onChange={(e) => setName(e.currentTarget.value)}
                        required
                    />
                </div>
                <div className={form.fieldset}>
                    <button type="submit" className="primary button w-full">
                        <LoadingAnimation loading={loading} /> Save Bill
                    </button>
                </div>
            </form>
        </div>
    );
};
