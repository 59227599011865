import { getAnalytics } from 'firebase/analytics';

import { getPerformance } from 'firebase/performance';
import { initializeApp } from 'firebase/app';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    authDomain: 'etica-android-app.firebaseapp.com',
    projectId: 'etica-android-app',
    measurementId: 'G-6H7SQD4R5R',
};

type AppConfig = {
    appId: string;
    apiKey: string;
    allowedEnvs: readonly string[];
};

export const initializeFirebase = ({
    appId,
    apiKey,
    allowedEnvs,
}: AppConfig) => {
    if (!apiKey || !appId) {
        console.info('Firebase not initialized. Missing ApiKey or AppId');
        return undefined;
    }

    const currentEnv = process.env.NODE_ENV ?? 'production';

    if (!allowedEnvs.includes(currentEnv)) {
        console.info(`Firebase not initialized for environment ${currentEnv}`);

        return undefined;
    }

    // Initialize Firebase
    const app = initializeApp({
        apiKey,
        appId,
        ...firebaseConfig,
    });

    return {
        app,
        analytics: getAnalytics(app),
        performance: getPerformance(app),
    };
};
