import React from 'react';

type Props = {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
    title?: string;
    text: string;
    primaryButtonText?: string;
    primaryButtonAction?: () => void;
    secondaryButtonText?: string;
    secondaryButtonAction?: () => void;
};
export const Modal: React.FC<Props> = ({
    showModal,
    setShowModal,
    title,
    text,
    primaryButtonText,
    primaryButtonAction,
    secondaryButtonAction,
    secondaryButtonText,
}) => {
    const secondaryBtn = () => {
        setShowModal(false);
        secondaryButtonAction && secondaryButtonAction();
    };
    const primaryBtn = () => {
        setShowModal(false);
        primaryButtonAction && primaryButtonAction();
    };
    return (
        <>
            {showModal ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-6 mx-auto max-w-sm">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                {title && (
                                    <div className="flex items-start justify-between p-2 border-b border-solid border-slate-200 rounded-t">
                                        <h3 className="text-xl font-semibold">
                                            {title}
                                        </h3>
                                        <button
                                            className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                            onClick={() => setShowModal(false)}
                                        >
                                            <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                                x
                                            </span>
                                        </button>
                                    </div>
                                )}
                                {/*body*/}
                                <div className="relative p-3 flex-auto">
                                    <p className="my-4 text-slate-500 text-base leading-relaxed">
                                        {text}
                                    </p>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-3 border-t border-solid border-slate-200 rounded-b">
                                    {secondaryButtonText && (
                                        <button
                                            className="secondary button outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => secondaryBtn()}
                                        >
                                            {secondaryButtonText}
                                        </button>
                                    )}
                                    {primaryButtonText && (
                                        <button
                                            className="primary button rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => primaryBtn()}
                                        >
                                            {primaryButtonText}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    );
};
