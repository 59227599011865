export function keys<T extends Record<string, unknown>>(obj: T): (keyof T)[] {
    return Object.keys(obj);
}

export function values<E, T extends Record<string, E>>(obj: T): E[] {
    return Object.values(obj);
}

export async function sleepForSeconds(durationSecs: number) {
    await new Promise((resolve) => setTimeout(resolve, durationSecs * 1000));
}

export function retryWithExponentialBackoff<T>(
    fn: () => T,
    maxAttempts = 5,
    baseDelayMs = 1000
) {
    let attempt = 1;

    const execute: () => Promise<T> = async () => {
        try {
            return await fn();
        } catch (error) {
            if (attempt >= maxAttempts) {
                throw error;
            }

            const delayMs =
                attempt === 0 ? 0 : baseDelayMs * 2 ** (attempt - 1);

            await new Promise((resolve) => setTimeout(resolve, delayMs));

            attempt++;
            return execute();
        }
    };

    return {
        result: execute(),
        attempts: attempt,
    };
}
