import { Aside, AsideProps } from '../ui/aside';
import { Confirmation, Stage } from '.';
import React, { useEffect, useRef, useState } from 'react';

import {
    createAccount,
    fetchInvestments,
} from '@etica-js/api/src/appState/actions';

import { InvestmentState } from '@etica-js/api/src/schema';
import { LOCK_IN_PERIODS } from './accountSettings';
import { LoadingAnimation } from '../ui/loading';

import { currencyFormat } from '../widgets/formatting';
import form from '../../assets/styles.module.scss';
import { toast } from 'react-toastify';
import { useAppContext } from '@etica-js/api/src/appState/state';

type Props = {
    onClose: AsideProps['onClose'];
};

export const Invest: React.FC<Props> = (props) => {
    const [amount, setAmount] = useState(0);
    const [selectedProduct, setSelectedProduct] = useState('');
    const [selectedClient, setSelectedClient] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('MPESA');
    const [loading, setLoading] = useState(false);
    const [complete, setComplete] = useState(false);
    const ref = useRef<HTMLSelectElement>(null);
    const [stage, setStage] = useState<Stage | undefined>(undefined);
    const [accountAlias, setAccountAlias] = useState<string | undefined>();
    const [lockIn, setLockIn] = useState<string | undefined>();
    const appCtx = useAppContext();
    const products = appCtx.products;
    const clients = appCtx.investments;

    useEffect(() => {
        setSelectedProduct(appCtx.ui.openModal.target);
        if (ref.current) {
            ref.current.value = appCtx.ui.openModal.target;
        }
    }, [appCtx.ui.openModal.target]);

    const getClientLabel = (client?: InvestmentState[number]) =>
        client ? `${client.name} - ${client.code}` : 'No client';

    const onSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        if (stage === undefined) {
            setStage({
                type: 'confirmation',
                message: 'Are you sure you want to create a new account?',
                data: [
                    {
                        label: 'Product',
                        value: products?.find(
                            (p) => p.short_name === selectedProduct
                        )?.name,
                    },
                    {
                        label: 'Client',
                        value: getClientLabel(
                            clients?.find(
                                (c) =>
                                    c.code?.toString() ===
                                    selectedClient?.toString()
                            )
                        ),
                    },
                    {
                        label: 'Amount',
                        value: currencyFormat({ value: amount }),
                    },
                    { label: 'Payment method', value: paymentMethod },
                    {
                        label: 'Alias',
                        value: accountAlias,
                    },
                    {
                        label: 'Lock In Period',
                        value: lockIn
                            ? Object.entries(LOCK_IN_PERIODS).find(
                                  ([k, v]) => k === lockIn
                              )?.[1] ?? lockIn
                            : 'None',
                    },
                ],
            });
            return;
        }
        setLoading(true);

        createAccount(
            amount,
            selectedClient,
            paymentMethod,
            selectedProduct,
            accountAlias,
            lockIn
        )
            .then((result) => {
                appCtx.logger?.logUserAction('new_account', result.success);
                if (!result.success) {
                    toast.error('Failed - ' + result.message);
                    return;
                }
                toast.success('Saved successfully');
                fetchInvestments(appCtx.dispatch);
                setComplete(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const fullProduct = products?.find((p) => p.short_name === selectedProduct);

    return (
        <Aside onClose={props.onClose} success={complete}>
            <div className="flex flex-wrap p-3">
                <h3 className="text-xl font-bold mb-5 mt-10">
                    Create A New Investment Account
                </h3>

                {(fullProduct?.fund.terms_link ||
                    fullProduct?.fund.terms_link) && (
                    <div className={form.infoBox + ' w-full'}>
                        {fullProduct?.fund.terms_link && (
                            <p className="mb-2">
                                By creating an account, you agree to our{' '}
                                <a
                                    className="text-blue-500"
                                    href={fullProduct.fund.terms_link}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    product terms
                                </a>
                            </p>
                        )}
                        {fullProduct?.fund.terms_link && (
                            <p className="mb-2">
                                By creating an account, you agree to our{' '}
                                <a
                                    className="text-blue-500"
                                    href={fullProduct.fund.terms_link}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    risk disclosure
                                </a>
                            </p>
                        )}
                    </div>
                )}

                <form onSubmit={onSubmit} className="w-full">
                    <div className="w-full">
                        {stage && stage.type === 'confirmation' && (
                            <Confirmation {...stage} />
                        )}
                        {!stage && (
                            <>
                                <div className={form.fieldset}>
                                    <label htmlFor="">Product</label>
                                    <select
                                        onChange={(e) =>
                                            setSelectedProduct(
                                                e.currentTarget.value
                                            )
                                        }
                                        defaultValue={
                                            appCtx.ui.openModal.target
                                        }
                                        disabled={!!appCtx.ui.openModal.target}
                                        ref={ref}
                                        required
                                    >
                                        <option value="">SELECT PRODUCT</option>
                                        {products?.map((prod) => (
                                            <option
                                                key={prod.short_name}
                                                value={prod.short_name}
                                            >
                                                {prod.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className={form.fieldset}>
                                    <label htmlFor="">Client</label>
                                    <select
                                        onChange={(e) =>
                                            setSelectedClient(
                                                e.currentTarget.value
                                            )
                                        }
                                        required
                                    >
                                        <option value="">SELECT CLIENT</option>
                                        {clients?.map((c) => (
                                            <option key={c.code} value={c.code}>
                                                {c.name} - {c.code}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className={form.fieldset}>
                                    <label htmlFor="">Amount</label>
                                    <input
                                        type="number"
                                        onChange={(e) =>
                                            setAmount(
                                                parseFloat(
                                                    e.currentTarget.value
                                                )
                                            )
                                        }
                                        required
                                    />
                                </div>
                                <div className={form.fieldset}>
                                    <label htmlFor="">Payment Method</label>
                                    <select
                                        name="payment"
                                        onChange={(e) =>
                                            setPaymentMethod(
                                                e.currentTarget.value
                                            )
                                        }
                                        required
                                    >
                                        <option value="MPESA">Mpesa</option>
                                        <option value="BANK">Bank</option>
                                    </select>
                                </div>

                                <div className={form.fieldset}>
                                    <label htmlFor="">
                                        Account Alias{' '}
                                        <span className={form.small}>
                                            You can give your account a name or
                                            a goal, e.g. school fees account
                                        </span>
                                    </label>
                                    <input
                                        type="text"
                                        onChange={(e) =>
                                            setAccountAlias(
                                                e.currentTarget.value
                                            )
                                        }
                                    />
                                </div>
                                {!fullProduct?.lock_in_period && (
                                    <div className={form.fieldset}>
                                        <label htmlFor="">
                                            Lock in period{' '}
                                            <span className={form.small}>
                                                You can customize how long you
                                                want to keep your investment
                                                before you can withdraw
                                            </span>
                                        </label>
                                        <select
                                            name="lock_in_period"
                                            onChange={(e) =>
                                                setLockIn(e.currentTarget.value)
                                            }
                                        >
                                            <option>None</option>
                                            {Object.entries(
                                                LOCK_IN_PERIODS
                                            ).map(([key, val]) => (
                                                <option key={key} value={key}>
                                                    {val}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                )}
                            </>
                        )}
                        <div className={form.fieldset}>
                            {stage && stage.type === 'confirmation' && (
                                <button
                                    className="secondary button w-full mb-2"
                                    onClick={() => setStage(undefined)}
                                >
                                    Back
                                </button>
                            )}
                            <button
                                type="submit"
                                className="primary button w-full"
                            >
                                <LoadingAnimation loading={loading} /> Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </Aside>
    );
};
