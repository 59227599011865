import { ApplicationForm, Form, InputElementEvent } from './form';
import { Link, useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { get, post } from '@etica-js/api/src/framework/http';
import { getStages, saveApplicationToContext, useFormNavigate } from '.';

import { LoadingAnimation } from '../../components/ui/loading';
import { Product } from '@etica-js/api/src/schema';
import formStyle from '../../assets/styles.module.scss';
import { toast } from 'react-toastify';
import { useAppContext } from '@etica-js/api/src/appState/state';

type Stage = { name: string; link: string; key: string };
type InvestForm = {
    amount?: number;
    product?: Product['short_name'];
    tenor?: number;
    method?: string;
};

export const Invest = () => {
    const { code, key } = useParams();
    const appCtx = useAppContext();
    const navigate = useFormNavigate();
    const [loading, setLoading] = useState(false);
    // eslint-disable-next-line no-undef
    const [stages, setStages] = useState<ReadonlyArray<Stage>>([]);
    const [products, setProducts] = useState<ReadonlyArray<Product>>([]);
    const [product, setProduct] = useState<Product | undefined>(undefined);

    const productOptions = useCallback(() => {
        return products?.map((p) => ({ label: p.name, value: p.short_name }));
    }, [products]);

    // eslint-disable-next-line no-undef
    const [formData, setFormData] = useState<InvestForm>({});

    const fetchData = async () => {
        const data = await saveApplicationToContext(
            code ?? '',
            key ?? '',
            appCtx
        );

        if (!data.success) {
            toast.error(`Failed - ${data?.message}`);
            return;
        }

        const classification = data.client?.classification;
        if (classification) {
            setStages(getStages(classification));
        }

        if (!data.success) {
            toast.error(`Failed - ${data?.message}`);
        }

        const productResp = await get('client-api/products');

        const productData = await productResp.json();

        if (productResp.ok) {
            setProducts(productData);
        } else {
            toast.error('Failed to fetch products');
        }
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code, key]);

    const saveInput = (event: InputElementEvent) => {
        setFormData({
            ...formData,
            [event.currentTarget.name]: event.currentTarget.value,
        });

        if (event.currentTarget.name === 'product') {
            const sp = products?.filter(
                (p) => p.short_name === event.currentTarget.value
            );

            setProduct(sp.length === 1 ? sp[0] : undefined);
        }
    };

    const form: Form = {
        groups: [
            {
                name: 'invest',
                fields: [
                    {
                        type: 'number',
                        name: 'amount',
                        label: 'Amount',
                        placeholder: 'Enter amount to invest',
                        onChange: saveInput,
                    },
                    {
                        type: 'select',
                        name: 'product',
                        label: 'Product',
                        onChange: saveInput,
                        options: [
                            { value: '', label: 'Select Product' },
                            ...productOptions(),
                        ],
                    },
                    {
                        type: 'number',
                        name: 'tenor',
                        label: 'Investment duration (in months)',
                        placeholder: 'Enter investment duration (months)',
                        hide: !(product?.fund.calculator === 'fixed_interest'),
                        optional:
                            product === undefined ||
                            product?.fund.calculator !== 'fixed_interest',
                        onChange: saveInput,
                    },
                    {
                        type: 'select',
                        name: 'payment_method',
                        label: 'Payment method',
                        onChange: saveInput,
                        options: [
                            { label: 'Select payment method', value: '' },
                            { label: 'M-PESA', value: 'mpesa' },
                            { label: 'Bank deposit/transfer', value: 'bank' },
                            { label: 'SasaPay', value: 'sasapay' },
                        ],
                    },
                ],
            },
        ],
    };

    const onSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        setLoading(true);

        post(`clients/applications/${code}/${key}/create-account`, {
            data: formData,
        })
            .then((resp) => {
                resp.json().then((data) => {
                    if (resp.ok) {
                        toast.success(
                            "Investment saved successfully. You've now completed the application."
                        );
                        // @ts-ignore
                        window.ReactNativeWebView?.postMessage?.(
                            'CLEAR_APPLICATIONS'
                        );
                        navigate(`/apply/${code}/${key}/complete`);
                        return;
                    }
                    toast.error(`Failed - ${data?.Message}`);
                });
            })
            .finally(() => setLoading(false));
    };

    const fullProduct = products?.find(
        (p) => p.short_name === formData.product
    );

    return (
        <ApplicationForm
            stages={stages}
            currentStage="invest"
            form={form}
            onSubmit={onSubmit}
            loading={loading}
        >
            <div className="w-full">
                {(fullProduct?.fund.terms_link ||
                    fullProduct?.fund.terms_link) && (
                    <div className={formStyle.infoBox + ' w-full'}>
                        {fullProduct?.fund.terms_link && (
                            <p className="mb-2">
                                By creating an account, you agree to our{' '}
                                <a
                                    className="text-blue-500"
                                    href={fullProduct.fund.terms_link}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    product terms
                                </a>
                            </p>
                        )}
                        {fullProduct?.fund.terms_link && (
                            <p className="mb-2">
                                By creating an account, you agree to our{' '}
                                <a
                                    className="text-blue-500"
                                    href={fullProduct.fund.terms_link}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    risk disclosure
                                </a>
                            </p>
                        )}
                    </div>
                )}
            </div>
        </ApplicationForm>
    );
};

export const Complete = () => {
    const canPostMessage = useMemo(() => {
        // @ts-ignore
        return window?.ReactNativeWebView;
    }, []);

    const postMessage = () => {
        if (!canPostMessage) {
            return;
        }

        // @ts-ignore
        window.ReactNativeWebView?.postMessage?.('CLEAR_APPLICATIONS');
        // @ts-ignore
        window.ReactNativeWebView?.postMessage?.('GO_TO_LOGIN');
    };
    return (
        <div className="flex flex-wrap text-center">
            <div className="w-full text-xl mt-10 mb-10">
                You have now completed your application
            </div>

            <div className="w-full text-sm mb-10">
                You will receive an SMS with details on how to make payment
            </div>

            <div className="w-full">
                {canPostMessage ? (
                    <button
                        className="primary button"
                        onClick={() => postMessage()}
                    >
                        Proceed to Login
                    </button>
                ) : (
                    <Link className="primary button" to="/auth/login">
                        Proceed to Login
                    </Link>
                )}
            </div>
        </div>
    );
};

export const Resume = () => {
    const { code, key } = useParams();
    const appCtx = useAppContext();
    const navigation = useFormNavigate();
    const [loading, setLoading] = useState(false);
    const [failed, setFailed] = useState(false);

    const navigate = (stage: string) => {
        return navigation(`/apply/${code}/${key}/${stage}`);
    };

    const fetchData = async () => {
        setLoading(true);
        const data = await saveApplicationToContext(
            code ?? '',
            key ?? '',
            appCtx
        ).finally(() => setLoading(false));

        setFailed(!data.success);
        if (!data.success) {
            toast.error(`Failed - ${data?.message}`);
            return;
        }

        if (data.client?.has_product) {
            return navigate('complete');
        }
        if (!data.client?.phone_verified) {
            return navigate('verify-phone');
        }
        if (!data.client?.email_verified) {
            return navigate('verify-email');
        }
        if (!data.client.has_user) {
            return navigate('create-password');
        }
        if (
            data.client?.classification !== 'individual' &&
            !data.client?.has_kyc
        ) {
            return navigate('kyc');
        }
        if (!data.client?.has_product) {
            return navigate('invest');
        }
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code, key]);

    return (
        <div className="flex flex-wrap text-center">
            <div className="w-full text-xl mt-10 mb-10">
                {loading && (
                    <div className="w-full flex flex-wrap items-center justify-center">
                        <div className="text-center">
                            <LoadingAnimation
                                color="text-blue-500"
                                loading={loading}
                            />
                        </div>
                        <div className="w-full text-center">
                            Loading your details...
                        </div>
                    </div>
                )}
                {failed && <div>Could not resume your application </div>}
            </div>
        </div>
    );
};
