import { NavBar } from '../components/navigation/navbar';
import React from 'react';

type Props = {
    children: JSX.Element;
};

export const Page: React.FC<Props> = (props) => {
    return (
        <div>
            <NavBar />
            <section className={`flex flex-wrap justify-center items-center`}>
                <div className={`md:max-w-full`}>{props.children}</div>
            </section>
        </div>
    );
};
