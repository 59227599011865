const prefix = 'eticadata.';

const inMemoryStorage: { [key: string]: string } = {};

function isSupported() {
    try {
        const testKey = '__some_random_key_you_are_not_going_to_use__';
        localStorage.setItem(testKey, testKey);
        localStorage.removeItem(testKey);
        return true;
    } catch (e) {
        return false;
    }
}

export const setItem = async <T>(
    key: string,
    value: T,
    serialize?: boolean
) => {
    const serialized = serialize ? JSON.stringify(value) : value;

    if (isSupported()) {
        localStorage.setItem(`${prefix}${key}`, serialized as string);
    } else {
        inMemoryStorage[`${prefix}${key}`] = serialized as string;
    }
};

export const getItem = async <T>(key: string): Promise<T | undefined> => {
    if (isSupported()) {
        return localStorage.getItem(`${prefix}${key}`) as unknown as T;
    } else {
        return inMemoryStorage[`${prefix}${key}`] as unknown as T;
    }
};

export const deleteItem = async (key: string) => {
    if (isSupported()) {
        localStorage.removeItem(`${prefix}${key}`);
    } else {
        delete inMemoryStorage[`${prefix}${key}`];
    }
};
