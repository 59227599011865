import { Link } from 'react-router-dom';
import logo from '@etica-js/assets/src/images/logo.svg';
import styles from './styles.module.scss';

export const NavBar = () => {
    return (
        <nav className={`flex flex-wrap items-center ${styles.header}`}>
            <div className="ml-5 mr-5 mt-2 mb-2">
                <Link to="/">
                    <img src={logo} alt="logo" />
                </Link>
            </div>
            <div className="ml-5 mr-5 mt-2 mb-2 flex flex-wrap grow justify-end">
                <ul
                    className={`flex flex-wrap pt-2 pb -2 pl-4 pr-4 ${styles.menuList}`}
                >
                    <li>
                        <Link to="/auth/login" className="primary button">
                            Login
                        </Link>
                    </li>
                    <li>
                        <a
                            className="secondary button"
                            target="_blank"
                            href="http://eticacap.com/"
                            rel="noreferrer"
                        >
                            About Etica
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    );
};
