import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useReducer,
    useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { User } from '../schema';
import { deleteLoginTokens } from '../framework/token';
import { get } from '../framework/http';
import { toast } from 'react-toastify';

type UserState = {
    user?: User;
};

type Action =
    | { type: 'RESET'; payload?: undefined }
    | { type: 'FETCH'; payload?: User };

export type AuthStateDispatch = React.Dispatch<Action>;

type AuthState = UserState & {
    dispatch?: AuthStateDispatch;
};

const initialState: AuthState = {
    user: undefined,
};

export const AuthContext = createContext({ ...initialState });

export const useAuthContext = () => {
    const context = useContext(AuthContext);

    if (context === undefined) {
        throw new Error('Auth context was used outside its provider');
    }

    return context;
};

export function setAsyncState<T extends Action>(
    promise: Promise<T['payload']>,
    dispatch: React.Dispatch<Action> | undefined,
    type: T['type']
) {
    promise.then((payload) => {
        const action = { type, payload } as Action;

        return dispatch?.(action);
    });
}

export const fetchUser = async (
    dispatch?: AuthStateDispatch
): Promise<User | undefined> => {
    const resp = await get('auth/user');

    if (resp.ok) {
        const user = await resp.json();

        dispatch?.({ type: 'FETCH', payload: user });

        return user;
    }

    dispatch?.({ type: 'FETCH', payload: undefined });
    return undefined;
};

export const logoutUser = async () => {
    const resp = await get('auth/user/logout');
    await deleteLoginTokens();

    return await resp.json();
};

export const AuthStatus: React.FC<{ children?: JSX.Element }> = ({
    children,
}) => {
    const tabActive = useTabActive();
    const authCtx = useAuthContext();
    const [warned, setWarned] = useState(false);
    const [lastCheck, setLastCheck] = useState(new Date());
    const nav = useLocation();
    const navigate = useNavigate();
    // const ONE_MIN = 60 * 1000;

    const refreshUser = async () => {
        const lastCheckedSecs =
            (new Date().valueOf() - lastCheck.valueOf()) / 1000;

        if (!authCtx.user) {
            // console.log('No user, abort', new Date());
            return;
        }

        if (tabActive && lastCheckedSecs >= 60) {
            const user = await fetchUser(authCtx.dispatch);

            if (!user && !warned) {
                toast.warn(
                    'Your session has expired. Please log in again to continue'
                );
                navigate(`/auth/login?redir=${nav.pathname}`);
                setWarned(true);
            }
            setLastCheck(new Date());
            // console.log('Refresh complete', new Date());
            return;
        }
        // console.log(
        //     `Skip checking auth status. Last checked ${lastCheckedSecs}s ${new Date()}`
        // );
    };

    // useEffect(() => {
    //     const interval = setInterval(refreshUser, 10 * ONE_MIN);
    //     return () => clearInterval(interval);
    // }, [authCtx.user]);

    useEffect(() => {
        if (tabActive) {
            refreshUser(); // check if user is still logged in when tab is resumed
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabActive]);

    useEffect(() => {
        setWarned(false); // reset warning when user navigate to new path
    }, [nav.pathname]);

    return <>{children}</>;
};

export const AuthWrapper: React.FC<{ children: JSX.Element }> = ({
    children,
}) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <AuthContext.Provider value={{ ...state, dispatch }}>
            {children}
        </AuthContext.Provider>
    );
};

function reducer(state: UserState, action: Action): AuthState {
    const { type, payload } = action;
    switch (type) {
        case 'FETCH':
            return { ...state, user: payload };
        case 'RESET':
            return { ...state, user: undefined };
        default:
            return state;
    }
}

export const useTabActive = () => {
    const [visibilityState, setVisibilityState] = useState(true);

    const handleVisibilityChange = useCallback(() => {
        setVisibilityState(document.visibilityState === 'visible');
    }, []);

    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener(
                'visibilitychange',
                handleVisibilityChange
            );
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return visibilityState;
};
