export const download = (data: Blob, file_name: string) => {
    const fileURL = window.URL.createObjectURL(data);
    // Setting various property values
    let alink = document.createElement('a');
    alink.href = fileURL;
    alink.download = file_name;
    alink.click();
};

export const downloadResponse = async (
    response: Response,
    file_name?: string
) => {
    if (response.ok) {
        const fileName =
            getFileNameFromResponse(response) ?? file_name ?? 'Unknown_file';

        download(await response.blob(), fileName);
    }

    throw Error('Could not download from failed response');
};

export const getFileNameFromResponse = (response: Response) => {
    const disposition = response.headers.get('Content-Disposition') ?? '';

    const parts = disposition.split(';');

    const fileName = parts.length > 1 ? parts[1] : undefined;

    const fileNameParts = fileName?.split('=') ?? [];

    return fileNameParts.length > 1
        ? fileNameParts[1].trim().replace('"', '').replace('"', '')
        : undefined;
};
