import { format, parseISO } from 'date-fns';

type Props = {
    value: number | string;
    currency?: string;
    makeAbsolute?: boolean;
    decimals?: number;
};

export function currencyFormat({
    value,
    currency,
    makeAbsolute,
    decimals,
}: Props) {
    if (!value && value !== 0) {
        return '';
    }

    if (typeof value === 'string') {
        value = parseFloat(value);
    }

    if (makeAbsolute) {
        value = Math.abs(value);
    }

    return (
        `${currency ? currency + ' ' : ''}` +
        value
            ?.toFixed?.(decimals ?? 2)
            .replace?.(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    );
}

export const DATE_FORMATS = {
    human: 'do MMM yyyy',
};

export const dateFormat = (date_string: string | Date, fmt?: string) => {
    const date =
        typeof date_string === 'string' ? parseISO(date_string) : date_string;
    return format(date, fmt ?? DATE_FORMATS.human);
};

export const dateFormatIso = (date_string: string | Date) => {
    const d =
        typeof date_string === 'string' ? parseISO(date_string) : date_string;
    return `${d.getFullYear()}-${('0' + (d.getMonth() + 1)).slice(-2)}-${(
        '0' + d.getDate()
    ).slice(-2)}`;
};
