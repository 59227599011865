import {
    ConsoleConsumer,
    ILogger,
    Logger,
    WebConsumer,
} from '@etica-js/utils/src/logger';
import { useEffect, useState } from 'react';

import { AppWrapper } from '@etica-js/api/src/appState/state';
import { AuthWrapper } from '@etica-js/api/src/auth/state';
import { CommonContainer } from '../layouts/commons';
import { ContextInitializer } from '@etica-js/api/src/appState/init';
import { Router } from './router';
import { initialize } from '@etica-js/utils/src/error-reporting';
import { initializeFirebase } from '@etica-js/utils/src/firebase';

export const AppProvider = () => {
    const [logger, setLogger] = useState<ILogger>();

    useEffect(() => {
        initialize('web', {
            key: `${process.env.REACT_APP_GCLOUD_API_KEY}`,
            projectId: `${process.env.REACT_APP_GCLOUD_PROJECT}`,
            version: `${process.env.REACT_APP_API_URL}`,
            allowedEnvs: ['production'],
        });

        const consumer = new WebConsumer(
            initializeFirebase({
                appId: process.env.REACT_APP_FIREBASE_APP_ID ?? '',
                apiKey: process.env.REACT_APP_FIREBASE_API_KEY ?? '',
                allowedEnvs: ['production', 'development'],
            })
        );

        setLogger(
            new Logger([
                consumer,
                new ConsoleConsumer(
                    false && process.env.NODE_ENV === 'development'
                ),
            ])
        );
    }, []);
    return (
        <AuthWrapper>
            <AppWrapper logger={logger}>
                <ContextInitializer>
                    <>
                        <CommonContainer />
                        <Router />
                    </>
                </ContextInitializer>
            </AppWrapper>
        </AuthWrapper>
    );
};
