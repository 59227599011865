import { LoginPane } from '../../components/auth/login';

import { post } from '@etica-js/api/src/framework/http';
import styles from '../../assets/styles.module.scss';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import { useState } from 'react';

function randomPassword(letters: number, numbers: number, either: number) {
    const chars = [
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz@!#$%^&*/?~', // letters
        '0123456789', // numbers
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@!#$%^&*/?~', // either
    ];

    function randInt(this_max: number) {
        // return int between 0 and this_max - 1
        let umax = Math.pow(2, 32);
        let max = umax - (umax % this_max);
        let r = new Uint32Array(1);
        do {
            crypto.getRandomValues(r);
        } while (r[0] > max);
        return r[0] % this_max;
    }

    function randCharFrom(chars: Array<string>) {
        return chars[randInt(chars.length)];
    }

    function shuffle(arr: Array<string>) {
        // https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle#The_modern_algorithm
        for (let i = 0, n = arr.length; i < n - 2; i++) {
            let j = randInt(n - i);
            [arr[j], arr[i]] = [arr[i], arr[j]];
        }
        return arr;
    }

    return shuffle(
        [letters, numbers, either]
            .map(function (len, i) {
                return Array(len)
                    .fill(chars[i])
                    .map((x) => randCharFrom(x))
                    .join('');
            })
            .concat()
            .join('')
            .split('')
    ).join('');
}

export const ResetPartnerSecret = () => {
    const { client_id, token } = useParams();
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [password_repeat, setPasswordRepeat] = useState('');
    const [passwordSaved, setPasswordSaved] = useState(false);

    const sendToken = async () => {
        const resp = await post('auth/partners/reset-secret', {
            data: {
                client_id: client_id ?? '',
                token: token ?? '',
                password,
                password_repeat,
            },
        });

        const data = await resp.json();

        if (!resp.ok) {
            toast.error(data.Message);
        }

        return data;
    };

    const generatePwd = (event?: React.FormEvent) => {
        event?.preventDefault();
        const pwd = randomPassword(120, 10, 10);
        setPassword(pwd);
        setPasswordRepeat(pwd);

        toast.info('Secret generated');
    };

    const changePwd = (pwd: string) => {
        setPassword(pwd);
        setPasswordRepeat(pwd);
    };

    const copyPwd = () => {
        if (!password) {
            toast.error('No secret to copy');
            return;
        }

        navigator.clipboard.writeText(password);

        toast.success('Secret copied to clipboard');
    };

    const onSubmit = () => {
        setLoading(true);

        sendToken()
            .then((data) => {
                if (data.success) {
                    setPasswordSaved(true);
                    toast.success(
                        'API Secret set successfully. Ensure you copy and keep it safe'
                    );
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <LoginPane
            title="Create your new API secret"
            description=""
            fields={[]}
            links={[]}
            submit={
                passwordSaved
                    ? undefined
                    : { value: 'Save', fn: onSubmit, loading }
            }
        >
            <div className="w-full">
                <div className="w-full">
                    {!passwordSaved && (
                        <p className="text-sm text-gray-500 mb-3">
                            Enter your new API secret or click generate. You may
                            use a password generator to generate a strong secret
                        </p>
                    )}
                    {!passwordSaved && (
                        <p className="text-sm text-gray-500 mb-5">
                            Must be at least 20 characters. Must contain:
                            uppercase, lowercase, number and special character
                        </p>
                    )}
                    <div className="flex w-full mb-3">
                        {!passwordSaved && (
                            <button
                                className="grow small secondary button mr-2"
                                onClick={generatePwd}
                            >
                                Generate
                            </button>
                        )}
                        {passwordSaved && (
                            <button
                                className="grow small secondary button"
                                onClick={() => {
                                    copyPwd();
                                }}
                            >
                                Copy
                            </button>
                        )}
                    </div>
                </div>
                <fieldset className={styles.fieldset}>
                    <textarea
                        name=""
                        id=""
                        rows={5}
                        onChange={(e) => changePwd(e.currentTarget.value)}
                        readOnly={passwordSaved}
                        value={password}
                    ></textarea>
                </fieldset>

                {passwordSaved && (
                    <p className="text-gray-600">
                        Secret saved successfully. Copy it and keep it safe!
                    </p>
                )}
            </div>
        </LoginPane>
    );
};
