const JSEncrypt = require('./sources/jsencrypt.min.js');

export const encrypt = (plaintext: string, publicKey: string) => {
    const jsEncrypt = new JSEncrypt();

    jsEncrypt.setPublicKey(publicKey);

    const encryptedText = jsEncrypt.encrypt(plaintext);

    return encryptedText;
};
