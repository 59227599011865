import { useEffect, useState } from 'react';

import { Currency } from '../widgets/formatting';
import { Link } from 'react-router-dom';
import styles from './widgets.module.scss';
import { useAppContext } from '@etica-js/api/src/appState/state';
import { useAuthContext } from '@etica-js/api/src/auth/state';

export const ProfileWidget = () => {
    const auth = useAuthContext();
    const appCtx = useAppContext();

    const setOpenModal = (payload: string) => {
        appCtx.dispatch({
            type: 'SET_OPEN_MODAL',
            payload: { name: payload, target: '' },
        });
    };

    const [valuation, setValuation] = useState({ value: 0, currency: '' });
    const [missingDocCount, setMissingDocCount] = useState(0);

    useEffect(() => {
        setValuation(appCtx.process('CALCULATE_TOTAL'));
        setMissingDocCount(appCtx.kycStatus?.count ?? 0);
    }, [appCtx]);

    return (
        <div
            className={
                styles.profileWidget + ' my-10 px-2 py-2 rounded flex flex-wrap'
            }
        >
            <div>
                <img
                    className="rounded-full"
                    style={{ height: 80, width: 80 }}
                    src={auth.user?.avatar}
                    alt="avatar"
                />
            </div>
            <div className={styles.profileText + ' mx-4 my-2 grow'}>
                <p className="font-bold text-black">
                    Hi {auth.user?.first_name} {auth.user?.last_name}
                </p>
                <p className={styles.grayText}>
                    Welcome to Etica Capital Investment platform
                </p>
                {missingDocCount > 0 && (
                    <p className={styles.redText}>
                        <Link to="/dashboard/profile">
                            Your profile has {missingDocCount} missing
                            documents. Click here to complete your profile
                        </Link>
                    </p>
                )}
            </div>
            <div
                className={styles.rightActionPanel + ' right-0 grow md:grow-0'}
            >
                <p className={styles.investment + ' mr-2'}>
                    TOTAL INVESTMENT VALUE
                </p>
                <p className={styles.amount + ' mr-2'}>
                    <Currency
                        value={valuation.value}
                        currency={valuation.currency}
                    />
                </p>
                <div className="flex flex-wrap justify-end">
                    <button
                        className="small primary button mr-2"
                        onClick={() => setOpenModal('topup')}
                    >
                        DEPOSIT
                    </button>
                    <button
                        className="small secondary button mr-2"
                        onClick={() => setOpenModal('withdraw')}
                    >
                        WITHDRAW
                    </button>
                </div>
            </div>
        </div>
    );
};
